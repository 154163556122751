<template>
  <v-list dense>
    <v-list-item>
      <v-list-item-avatar>
        <v-icon>person</v-icon>
      </v-list-item-avatar>
      <v-list-item-content>
        <div class="font-weight-light">Name:</div>
        {{ user.forename + " " + user.surname }}
      </v-list-item-content>
    </v-list-item>
    <v-list-item>
      <v-list-item-avatar>
        <v-icon>cake</v-icon>
      </v-list-item-avatar>
      <v-list-item-content>
        <div class="font-weight-light">Geburtsdatum:</div>
        {{ parseDate(user.birthday) }}
      </v-list-item-content>
    </v-list-item>
    <v-list-item>
      <v-list-item-avatar>
        <v-icon>email</v-icon>
      </v-list-item-avatar>
      <v-list-item-content>
        <div class="font-weight-light">Email:</div>
        {{ user.email }}
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
import Parser from "@/utils/Parser";

export default {
  name: "ReducedUserDetailList",
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  methods: {
    parseDate(date) {
      return Parser.parseDate(new Date(date));
    }
  }
};
</script>

<style scoped></style>
