<template>
  <v-stepper-content :step="step">
    <v-card>
      <v-card-title>
        Einverständnis
      </v-card-title>
      <v-card-text style="max-width: 700px">
        <h3>Kinderschutz</h3>
        <p>
          Schutz vor Missbrauch und sexueller Gewalt ist ein wichtiges Thema,
          mit dem wir uns als Ketscher Pfadfinder aktiv auseinandersetzen. An
          unseren Lagern nehmen ausgebildete Leiter teil, die für diese Thematik
          sensibilisiert sind. Desweiteren stehen wir in Kontakt mit den
          Jugendämtern. Kinder müssen sich nicht verstecken. Bei entsprechenden
          Anliegen können und sollen sich Kindern und/oder Eltern an eine
          unabhängige Stelle wenden: Bundesweites Opfer-Telefon: 116 006.
        </p>
        <h3 class="mt-3">Teilnahmebedingungen</h3>
        <p>
          Liebe Eltern, zur optimalen Durchführung von Ferienlagern tragen auch
          klare Vereinbarungen über die Rechte und Pflichten bei, die wir mit
          ihnen in Form der nachfolgenden Teilnahmebedingungen treffen wollen.
          Lesen sie daher diese Teilnahmebedingungen vor der Anmeldung
          sorgfältig durch. Wir bitten um ihr Verständnis und ihre
          Unterstützung.
        </p>
        <h4>
          Anzahlung
        </h4>
        <p>
          Im Falle eines kurzfristigen Rücktrittes von der Fahrt behalten wir
          uns vor, nur einen Teil des Gesamtbeitrags zurückzuerstatten.
        </p>
        <h4>
          Aufsichtspflicht
        </h4>
        <p>
          Ich/Wir übertrage/n für die Zeit des Lagers dem Leitungsteam die
          Aufsichtspflicht für mein/unser Kind. Mir/Uns ist bekannt, dass das
          Leitungsteam für Schäden und Unfälle die durch Nichtbefolgung der
          Anordnungen durch mein/unser Kind entstehen, nicht haftbar gemacht
          werden kann. Außerdem ist mir/uns bekannt, dass meinem/unserem Kind
          stundenweise Freizeit gewährt wird und in dieser Zeit die
          Aufsichtspflicht des Leitungsteams eingeschränkt ist.
        </p>
        <h4>
          Erziehungsberechtigung
        </h4>
        <p>
          Im Sinne des Jugendschutzes übertrage/n ich/wir für die Dauer der
          Veranstaltung dem Leitungsteam die Erziehungsberechtigung für
          mein/unser Kind [JÖSchG §2(2)}.
        </p>
        <h4>
          Zuwiderhandlung von Anweisungen
        </h4>
        <p>
          Ferner bin/sind ich/wir einverstanden, sollte sich mein Kind
          wiederholt den Anweisungen des Leitungsteams widersetzen und sich
          somit seine weitere Teilnahme am Lager als unmöglich gestalten, dass
          mein/unser Kind abgeholt werden muss oder gegen Erstattung der
          Fahrkosten nach Hause geschickt wird.
        </p>
        <h4>
          Teilnahme an Veranstaltungen
        </h4>
        <p>
          Ich/wir erlaube/n, dass mein/unser Kind an allen Veranstaltungen des
          Lagers uneingeschränkt teilnimmt.
        </p>
        <h4>
          Angaben zur Gesundheit
        </h4>
        <p>
          Der Teilnehmer bzw. dessen gesetzlichen Vertreter verpflichten sich,
          der Lagerleitung alle nötigen Unterlagen zur Gesundheit des Kindes
          zukommen zulassen. Dazu gehören auch Informationen zu speziellen
          Krankheiten, Verhaltensauffälligkeiten etc. des Kindes.
        </p>
        <h4>
          Ärztlicher Eingriff
        </h4>
        <p>
          Sollte während des Lagers ein dringender ärztlicher Eingriff bei
          meinem/unserem Kind vorgenommen werden müssen, so erteile/n ich/wir
          hiermit dem behandelnden Arzt die erforderliche Genehmigung. Von
          Dritten in diesem Zusammenhang zu treffende Entscheidungen übertrage/n
          ich/wir dem Leitungsteam, die diese mit dem zuständigen Arzt abwägen
          wird. Von dem Leitungsteam erwarte/n ich/wir, dass sie mich/uns
          unverzüglich hierüber benachrichtigt.
        </p>
        <h4>
          Versicherungsschutz
        </h4>
        <p>
          Alle Teilnehmer mit gültiger DPSG-Mitgliedschaft sind für die Dauer
          des Lagers über die DPSG versichert. Die Versicherung schließt Kosten
          bei Krankheit, Unfall oder Haftpflicht ein. Bei einem
          Auslandsaufenthalt wird zusätzlich eine Auslandskrankenversicherung
          über die <strong>Ecclesia</strong> (Versicherungspartner der DPSG)
          abgeschlossen.
        </p>
        <h4>
          Verwendung persönlicher Daten
        </h4>
        <p>
          Mit der Anmeldung wird die Zustimmung erteilt, die angegebenen Daten
          ausschließlich für die Zwecke der Durchführung sowie Vor- und
          Nachbereitung der Veranstaltung zu verwenden und ausschließlich für
          diese Zwecke auch an Dritte weiterzugeben.
        </p>
        <h4>
          Verwendung von Foto und Videoclips
        </h4>
        <p>
          Der Teilnehmende bzw. dessen Erziehungsberechtigte/r erklärt/erklären
          sich damit einverstanden, dass wir im Lager entstandene Fotos und
          Videoclips für unseren Internetauftritt und in gedruckter Form (z.B.
          Zeitung und Infobroschüren) für unsere Öffentlichkeitsarbeit
          zweckgebunden verwenden. Auf Verlangen können die auf unserer Homepage
          verwendeten einzelnen Fotos oder Videos im Nachhinein gelöscht werden.
        </p>
        <h4>
          Salvatorische Klausel
        </h4>
        <p>
          Sollten eine oder mehrere der vorstehenden Klauseln ganz oder
          teilweise nichtig, unwirksam oder undurchführbar sein oder werden,
          bleiben die übrigen Bedingungen wirksam. An deren Stelle tritt eine
          entsprechende gültige Klausel. Gleiches gilt bei Vorliegen einer
          Regelungslücke.
        </p>

        <h3 class="mt-3">
          Lagerregeln
        </h3>
        <p>
          Die Lagerregeln werden von dem Leitungsteam im Vorfeld festgelegt und
          den Teilnehmern mitgeteilt. Die Lagerregeln können sich
          situationsbedingt während des Aufenthaltes ändern. Die vorhandenen
          Platz- oder Hausregeln sind gültig.
        </p>
        <h4>
          Smartphone, Handy
        </h4>
        <p>
          Die Nutzung von elektronischen Geräten wie Handys, Smartphones, etc.
          ist während des Lagers nicht gestattet. Wir werden mitgebrachte Geräte
          konsequent einsammeln und auf Nachfrage mindestens einmal täglich
          ausgeben. Für verlorengegangene oder beschädigte Geräte übernehmen wir
          keine Haftung!
        </p>
        <h4>
          Lagertätigkeiten
        </h4>
        <p>
          Alle anfallende Aktionen, Aktivitäten und Lagerätigkeiten werden wie
          in allen Lagern üblich gemeinschaftlich durchgeführt. Dies schließt
          auch die Zubereitung von Mahlzeiten und ggf. Säuberung von
          Sanitäranlagen mit ein.
        </p>
        <h4>
          Messer
        </h4>
        <p>
          Messer mit feststehenden Klingen sind verboten.
        </p>
        <h4>
          Kleingruppen
        </h4>
        <p>
          Der Lagerplatz darf nur in Absprache mit der Gruppen- oder
          Lagerleitung verlassen werden. Die Teilehmer dürfen sich in Gruppen
          von mindestens drei Personen in unbekannten Orten eigenständig
          bewegen, sofern nichts anderes vereinbart ist.
        </p>
        <v-form ref="consent">
          <v-checkbox
            v-model="consentData.agreement"
            :rules="[v => !!v || 'Teilnahme nur mit Zustimmung möglich!']"
          >
            <template slot="label">
              <div>
                Ich habe die Teilnahmebedingungen und Lagerregeln gelesen und
                stimme diesen zu
              </div>
            </template>
          </v-checkbox>
        </v-form>
        <!--        <v-checkbox v-model="consentData.dataReuse">  disable until feature is implemented
          <template slot="label">
            <div>
              Ich stimme zu, dass meine Anmeldungsdaten über die Aktion hinaus
              gespeichert werden. Bei der nächsten Anmeldung wird diese mit den
              alten Daten vorausgefüllt. Die Daten können jederzeit
              selbstständig gelöscht werden.
            </div>
          </template>
        </v-checkbox>-->
      </v-card-text>
      <v-card-actions>
        <v-btn text class="ml-2" @click="back()">Zurück</v-btn>
        <v-btn class="ml-2" color="success" @click="register()">Anmelden</v-btn>
      </v-card-actions>
    </v-card>
  </v-stepper-content>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "ConsentData",
  props: {
    goToStep: Function,
    step: Number,
    eventId: Number
  },
  data() {
    return {
      consentData: {
        agreement: false,
        dataReuse: false
      }
    };
  },
  computed: {
    registrationProgress() {
      let index = this.$store.state.events.registrationsInProgress
        .map(reg => reg.eventId)
        .indexOf(this.eventId);
      return this.$store.state.events.registrationsInProgress[index];
    }
  },
  methods: {
    ...mapActions("events", [
      "setStepDone",
      "setStepError",
      "saveRegistrationState",
      "finishRegistration"
    ]),
    back() {
      this.setStepError({ eventId: this.eventId, value: false });
      this.setStepDone({ eventId: this.eventId, step: this.step - 2 });
      this.goToStep(this.step - 1);
    },
    register() {
      if (this.$refs.consent.validate()) {
        this.finishRegistration(this.eventId);
      } else {
        this.setStepError({ eventId: this.eventId, value: true });
      }
    },
    saveState() {
      let payload = {
        eventId: this.eventId,
        field: "consentData",
        data: this.consentData
      };
      this.saveRegistrationState(payload);
    },
    loadState() {
      this.consentData = this.registrationProgress.consentData;
    }
  },
  mounted() {
    this.loadState();
    window.addEventListener("beforeunload", this.saveState);
  },
  beforeDestroy() {
    window.removeEventListener("beforeunload", this.saveState);
  }
};
</script>

<style scoped>
p {
  text-align: justify;
}
</style>
