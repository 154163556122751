<template>
  <div>
    <div v-if="dietModuleActive">
      <v-card-title>
        Ernährung
      </v-card-title>
      <v-card-subtitle>
        Angaben zur Ernährung, Lebensmittelallergien und Unverträglichkeiten
      </v-card-subtitle>
      <v-card-text>
        <v-form ref="dietForm" :disabled="!editable">
          <v-autocomplete
            style="max-width: 600px"
            v-model="regData.dietData.diet"
            :items="diets"
            prepend-icon="restaurant"
            :rules="[v => !!v || 'Ernährungsangabe benötigt']"
          >
            <template v-slot:label>
              <div>
                Ernährung:
              </div>
            </template>
          </v-autocomplete>
          <v-checkbox v-model="regData.dietData.intolerance">
            <template slot="label">
              <div>
                Lebensmittel Unverträglichkeiten/Allergien
              </div>
            </template>
          </v-checkbox>
          <div v-if="regData.dietData.intolerance">
            <v-textarea
              outlined
              v-model="regData.dietData.intoleranceDescription"
              label="Unverträglichkeiten/Allergien"
              style="max-width: 600px"
              :rules="[
                v =>
                  (!!v && regData.dietData.intolerance) ||
                  'Angabe zu Unverträglichkeiten/Allergien benötigt'
              ]"
            />
          </div>
        </v-form>
      </v-card-text>
    </div>
    <div v-if="swimmingModuleActive">
      <v-card-title>
        Schwimmen
      </v-card-title>
      <v-card-subtitle>
        Angaben über deine Schwimmfähigkeiten
      </v-card-subtitle>
      <v-card-text>
        <v-radio-group
          v-model="regData.swimmingData.swimmer"
          :row="radioButtonGroupRow"
          :disabled="!editable"
        >
          <template v-slot:label>
            <div :class="{ radioLabel: radioButtonGroupRow }">
              Schwimmen
            </div>
          </template>
          <v-radio label="Schwimmer" :value="true"></v-radio>
          <v-radio
            label="Nicht-Schwimmer"
            :value="false"
          ></v-radio> </v-radio-group
        ><v-radio-group
          v-model="regData.swimmingData.onlySupervisedSwimming"
          :row="radioButtonGroupRow"
          :disabled="!editable"
        >
          <template v-slot:label>
            <div :class="{ radioLabel: radioButtonGroupRow }">
              Schwimmen - Aufsicht
            </div>
          </template>
          <v-radio label="nein (unbeaufsichtigt)" :value="false"></v-radio>
          <v-radio label="ja (beaufsichtigt)" :value="true"></v-radio>
        </v-radio-group>
      </v-card-text>
    </div>
    <div v-if="pocketMoneyModuleActive">
      <v-card-title>
        Taschengeld
      </v-card-title>
      <v-card-subtitle>
        Angabe zur Taschengeldverwaltung
      </v-card-subtitle>
      <v-card-text>
        <v-checkbox
          v-model="regData.pocketMoneyData.managedPocketMoney"
          :disabled="!editable"
        >
          <template slot="label">
            <div>
              Taschengeld soll von den Leitern verwaltet werden
            </div>
          </template>
        </v-checkbox>
      </v-card-text>
    </div>
    <div v-if="medicalDataModuleActive">
      <v-card-title>
        Medizinische Angaben
      </v-card-title>
      <v-card-subtitle>
        Angaben zu Vorerkrankungen, erlaubten Behandlungsmethoden und
        eventueller Medikation
      </v-card-subtitle>
      <v-card-text>
        <div class="text-subtitle-1">
          <v-icon style="transform: rotate(45deg)">
            healing
          </v-icon>
          Erlaubnis zu typischen Behandlungen und Medikamenten
        </div>
        <v-radio-group
          v-model="regData.medicalData.bepanthen"
          :row="radioButtonGroupRow"
          :disabled="!editable"
        >
          <template v-slot:label>
            <div :class="{ radioLabel: radioButtonGroupRow }">
              <div>Wundheilsalbe: Bepanthen</div>
              <div>(Wirkstoff: Dexpanthenol)</div>
            </div>
          </template>
          <v-radio label="ja (erlaubt)" :value="true"></v-radio>
          <v-radio label="nein (verboten)" :value="false"></v-radio>
        </v-radio-group>
        <v-radio-group
          v-model="regData.medicalData.fenistil"
          :row="radioButtonGroupRow"
          :disabled="!editable"
        >
          <template v-slot:label>
            <div :class="{ radioLabel: radioButtonGroupRow }">
              <div>Insektenstiche: Fenistil</div>
              <div>(Wirkstoff: Dimetinden, Dimetinden maleat)</div>
            </div>
          </template>
          <v-radio label="ja (erlaubt)" :value="true"></v-radio>
          <v-radio label="nein (verboten)" :value="false"></v-radio>
        </v-radio-group>
        <v-radio-group
          v-model="regData.medicalData.octenisept"
          :row="radioButtonGroupRow"
          :disabled="!editable"
        >
          <template v-slot:label>
            <div :class="{ radioLabel: radioButtonGroupRow }">
              <div>Desinfektion: Octenisept</div>
              <div>
                (Wirkstoff: 2-Phenoxyethanol, Octenidin, Octenidin
                dihydrochlorid)
              </div>
            </div>
          </template>
          <v-radio label="ja (erlaubt)" :value="true"></v-radio>
          <v-radio label="nein (verboten)" :value="false"></v-radio>
        </v-radio-group>
        <v-checkbox
          v-model="regData.medicalData.tick"
          readonly
          :disabled="!editable"
        >
          <template slot="label">
            <v-col>
              <div>
                Zecken dürfen von Leiter/Leiterin entfernt werden
              </div>
              <div class="font-weight-light">
                (Notwendig für die Teilnahme)
              </div>
            </v-col>
          </template>
        </v-checkbox>
        <v-form ref="medicalDataForm" :disabled="!editable">
          <v-checkbox v-model="regData.medicalData.precondition">
            <template slot="label">
              <div>
                Vorerkrankung vorhanden
              </div>
            </template>
          </v-checkbox>
          <div v-if="regData.medicalData.precondition">
            <v-textarea
              outlined
              style="max-width: 600px"
              v-model="regData.medicalData.preconditionDescription"
              label="Beschreibung der Vorerkrankung"
              prepend-icon="local_hospital"
              :rules="[
                v =>
                  (!!v && regData.medicalData.precondition) ||
                  'Bitte Vorerkrankung beschreiben'
              ]"
            />
          </div>
          <v-checkbox v-model="regData.medicalData.medication">
            <template slot="label">
              <div>
                Medikation
              </div>
            </template>
          </v-checkbox>
          <div v-if="regData.medicalData.medication">
            <v-textarea
              outlined
              style="max-width: 600px"
              v-model="regData.medicalData.medicationDescription"
              label="Medikamente"
              prepend-icon="local_pharmacy"
              :rules="[
                v =>
                  (!!v && regData.medicalData.medication) ||
                  'Bitte benötigte Medikamente angeben'
              ]"
            />
            <v-checkbox v-model="regData.medicalData.noSelfMedication">
              <template slot="label">
                <div>
                  Medikation durch das Leiterteam
                </div>
              </template>
            </v-checkbox>
            <div v-if="regData.medicalData.noSelfMedication">
              <v-textarea
                outlined
                style="max-width: 600px"
                v-model="regData.medicalData.medicationPlan"
                label="Medikationsplan"
                prepend-icon="schedule"
                :rules="[
                  v =>
                    (!!v && regData.medicalData.noSelfMedication) ||
                    'Bitte Medikationsplan angeben'
                ]"
              />
            </div>
          </div>
        </v-form>
      </v-card-text>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "RegistrationEditor",
  props: {
    eventId: Number,
    editable: Boolean,
    radioButtonGroupRow: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    event() {
      return this.$store.state.events.events.find(
        event => event.id === this.eventId
      );
    },
    dietModuleActive() {
      return this.event.registrationInformation.registrationModuleDiet;
    },
    swimmingModuleActive() {
      return this.event.registrationInformation.registrationModuleSwimming;
    },
    pocketMoneyModuleActive() {
      return this.event.registrationInformation.registrationModulePocketMoney;
    },
    medicalDataModuleActive() {
      return this.event.registrationInformation.registrationModuleMedicalData;
    }
  },
  data() {
    return {
      regData: {},
      diets: ["Fleisch", "Vegetarisch", "Vegan"],
      pocketMoneyData: {
        managedPocketMoney: false
      }
    };
  },
  methods: {
    ...mapActions("events", ["updateRegistration"]),
    loadRegistrationData() {
      this.regData = JSON.parse(
        JSON.stringify(
          this.$store.state.events.registrations.find(
            reg => reg.eventId === this.eventId
          )
        )
      );
    },
    updateRegistrationData() {
      if (
        this.$refs.dietForm.validate() & this.$refs.medicalDataForm.validate()
      ) {
        let regDataBeforeEdit = this.$store.state.events.registrations.find(
          reg => reg.eventId === this.eventId
        );
        if (
          JSON.stringify(this.regData) === JSON.stringify(regDataBeforeEdit)
        ) {
          return true;
        }
        this.updateRegistration(this.regData);
        return true;
      } else {
        return false;
      }
    }
  },
  created() {
    this.loadRegistrationData();
  }
};
</script>

<style scoped>
.radioLabel {
  width: 500px;
}
</style>
