<script>
import ConsentData from "./ConsentData";
import EmergencycontactsData from "./EmergencycontactsData";
import MedicalData from "./MedicalData";
import GeneralData from "./GeneralData";
import BasicData from "./BasicData";
import {
  VStepper,
  VStepperHeader,
  VStepperItems,
  VStepperStep,
  VDivider
} from "vuetify/lib";
import { mapActions } from "vuex";
import GuestUserForm from "@/components/registration/GuestUserForm";

export default {
  name: "RegistrationStepper",
  props: {
    eventId: {
      type: Number,
      required: true
    },
    guestRegistration: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      currentStep: 1
    };
  },
  components: {
    "guest-user-form": GuestUserForm,
    "consent-data": ConsentData,
    "emergency-contacts-data": EmergencycontactsData,
    "medical-data": MedicalData,
    "general-data": GeneralData,
    "basic-data": BasicData,
    "v-stepper": VStepper,
    "v-stepper-header": VStepperHeader,
    "v-stepper-items": VStepperItems,
    "v-stepper-step": VStepperStep,
    "v-divider": VDivider
  },
  render(createElement) {
    let modules = this.getModules();
    let headerSteps = [];
    let stepperItems = [];
    let vueI = this;
    for (let i = 0; i < modules.length; i++) {
      let rules = [
        function() {
          return (
            vueI.registrationProgress.currentStep !== i ||
            !vueI.registrationProgress.errorState
          );
        }
      ];
      headerSteps.push(
        createElement(
          "v-stepper-step",
          {
            props: {
              step: i + 1,
              //editable: true,
              rules: rules,
              complete: this.registrationProgress.currentStep >= i + 1
            }
          },
          modules[i].name
        )
      );
      i + 1 === modules.length || headerSteps.push(createElement("v-divider"));
      stepperItems.push(
        createElement(modules[i].component, {
          props: {
            step: i + 1,
            goToStep: this.goToStep,
            key: i + 1,
            eventId: this.eventId
          }
        })
      );
    }
    let header = createElement("v-stepper-header", headerSteps);
    let steps = createElement("v-stepper-items", stepperItems);
    return createElement("v-stepper", { props: { value: this.currentStep } }, [
      header,
      steps
    ]);
  },
  methods: {
    ...mapActions("events", ["setStepError"]),
    goToStep(n) {
      this.currentStep = n;
    },
    getGeneralDataName() {
      let swimmingModuleActive = this.registrationInformation
        .registrationModuleSwimming;
      let dietModuleActive = this.registrationInformation
        .registrationModuleDiet;
      let pocketMoneyModuleActive = this.registrationInformation
        .registrationModulePocketMoney;
      if (dietModuleActive) {
        if (swimmingModuleActive || pocketMoneyModuleActive) {
          return "Allgemein";
        } else {
          return "Ernährung";
        }
      } else if (swimmingModuleActive) {
        if (dietModuleActive || pocketMoneyModuleActive) {
          return "Allgemein";
        } else {
          return "Schwimmen";
        }
      } else {
        if (dietModuleActive || swimmingModuleActive) {
          return "Allgemein";
        } else {
          return "Taschengeld";
        }
      }
    },
    getModules() {
      let modules = [];
      if (this.guestRegistration) {
        modules.push({ component: "guest-user-form", name: "Stammdaten" });
      } else {
        modules.push({ component: "basic-data", name: "Stammdaten" });
      }
      if (
        this.registrationInformation.registrationModuleDiet ||
        this.registrationInformation.registrationModuleSwimming ||
        this.registrationInformation.registrationModulePocketMoney
      ) {
        modules.push({
          component: "general-data",
          name: this.getGeneralDataName()
        });
      }
      if (this.registrationInformation.registrationModuleMedicalData) {
        modules.push({
          component: "medical-data",
          name: "Medizinische Angaben"
        });
      }
      modules.push({
        component: "emergency-contacts-data",
        name: "Notfallkontakte"
      });
      modules.push({
        component: "consent-data",
        name: "Einverständnis"
      });
      return modules;
    }
  },
  computed: {
    user() {
      return this.$store.state.user.userInfo;
    },
    event() {
      let index = this.$store.state.events.events
        .map(event => event.id)
        .indexOf(this.eventId);
      return this.$store.state.events.events[index];
    },
    registrationInformation() {
      return this.event.registrationInformation;
    },
    registrationProgress() {
      let index = this.$store.state.events.registrationsInProgress
        .map(reg => reg.eventId)
        .indexOf(this.event.id);
      if (index < 0) {
        return {};
      }
      return this.$store.state.events.registrationsInProgress[index];
    }
  },
  mounted() {
    this.setStepError({ eventId: this.eventId, value: false });
    this.currentStep = this.registrationProgress.currentStep + 1;
  }
};
</script>

<style scoped>
.v-stepper__content {
  padding: 8px;
}
/*  .v-stepper__header {   //stick header
    width: -moz-available;
    width: available;
    position: fixed;
    background: white;
    z-index: 1;
    border-top-width: 0;
  }
  .v-stepper__items {
    margin-top: 70px;
  }*/
</style>
