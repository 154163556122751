<template>
  <v-card>
    <v-card-title>
      Email Adresse ändern
    </v-card-title>
    <v-card-subtitle class="mt-0">
      Aktuell ist die Email
      <span class="font-weight-bold">{{ account.email }}</span> in Verwendung.
      Nach der Änderung wird für den Login die neue Email verwendet und alle
      Informationen von Scouttools werden an diese Email gesendet werden.
    </v-card-subtitle>
    <v-card-text>
      <v-form
        v-model="newEmailForm"
        @submit.prevent="changeEmail()"
        id="change-email-form"
      >
        <v-text-field
          v-model="newEmail"
          :rules="emailRules"
          prepend-icon="email"
          name="email"
          label="Neue Email"
          type="text"
          required
        />
      </v-form>
      <v-alert v-if="info.display" border="left" type="success">
        {{ info.text }}
      </v-alert>
      <v-alert v-if="error.occured" border="left" type="error">
        {{ error.text }}
      </v-alert>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn text @click="cancel()" v-if="!info.display">
        Abbrechen
      </v-btn>
      <v-btn
        v-if="!info.display"
        :disabled="!newEmail || !newEmailForm"
        type="submit"
        color="primary"
        form="change-email-form"
        >Abschicken</v-btn
      >
      <v-btn text @click="cancel()" v-if="info.display">
        Schließen
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import AccountAPI from "../../api/AccountAPI";

export default {
  name: "ChangeEmail",
  props: {
    cancel: Function
  },
  data() {
    return {
      newEmail: "",
      newEmailForm: false,
      error: {
        occured: false,
        text: ""
      },
      info: {
        display: false,
        text: ""
      },
      emailRules: [
        v => !!v || "Email wird benötigt",
        v => /.+@.+\..+/.test(v) || "Bitte verwende eine gültige Email"
      ]
    };
  },
  computed: {
    account() {
      return this.$store.state.user.userInfo;
    }
  },
  methods: {
    changeEmail() {
      let vueI = this;
      vueI.error.occured = false;
      vueI.info.display = false;
      AccountAPI.changeEmail(this.newEmail)
        .then(response => {
          console.log(response);
          vueI.error.occured = false;
          vueI.info = {
            display: true,
            text:
              "In den nächsten Minuten wird eine Aktivierungs-Link an deine neue Email gesendet. Dieser Link ist die nächsten 24 Stunden gültig."
          };
        })
        .catch(error => {
          console.log(error);
          vueI.info.display = false;
          if (error.response.data.includes("email address already in use")) {
            error.response.data = "Email Adresse wird bereits benutzt";
          }
          vueI.error = {
            occured: true,
            text: error.response.data
          };
        });
    }
  }
};
</script>

<style scoped></style>
