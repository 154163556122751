<template>
  <v-stepper-content :step="step">
    <v-card>
      <v-card-title>
        Medizinische Angaben
      </v-card-title>
      <v-card-subtitle>
        Angaben zu Vorerkrankungen, erlaubten Behandlungsmethoden und
        eventueller Medikation
      </v-card-subtitle>
      <v-card-text>
        <div class="text-subtitle-1">
          <v-icon>
            healing
          </v-icon>
          Erlaubnis zu typischen Behandlungen und Medikamenten
        </div>
        <v-radio-group v-model="medicalData.bepanthen" :row="!isMobile">
          <template v-slot:label>
            <div :class="{ radioLabel: !isMobile }">
              <div>Wundheilsalbe: Bepanthen</div>
              <div>(Wirkstoff: Dexpanthenol)</div>
            </div>
          </template>
          <v-radio label="ja (erlaubt)" :value="true"></v-radio>
          <v-radio label="nein (verboten)" :value="false"></v-radio>
        </v-radio-group>
        <v-radio-group v-model="medicalData.fenistil" :row="!isMobile">
          <template v-slot:label>
            <div :class="{ radioLabel: !isMobile }">
              <div>Insektenstiche: Fenistil</div>
              <div>(Wirkstoff: Dimetinden, Dimetinden maleat)</div>
            </div>
          </template>
          <v-radio label="ja (erlaubt)" :value="true"></v-radio>
          <v-radio label="nein (verboten)" :value="false"></v-radio>
        </v-radio-group>
        <v-radio-group v-model="medicalData.octenisept" :row="!isMobile">
          <template v-slot:label>
            <div :class="{ radioLabel: !isMobile }">
              <div>Desinfektion: Octenisept</div>
              <div>
                (Wirkstoff: 2-Phenoxyethanol, Octenidin, Octenidin
                dihydrochlorid)
              </div>
            </div>
          </template>
          <v-radio label="ja (erlaubt)" :value="true"></v-radio>
          <v-radio label="nein (verboten)" :value="false"></v-radio>
        </v-radio-group>
        <v-divider class="mt-3" />
        <v-checkbox v-model="medicalData.tick" readonly>
          <template slot="label">
            <v-col>
              <div>
                Zecken dürfen von Leiter/Leiterin entfernt werden
              </div>
              <div class="font-weight-light">
                (Notwendig für die Teilnahme)
              </div>
            </v-col>
          </template>
        </v-checkbox>
        <v-form ref="medicalDataForm">
          <v-checkbox v-model="medicalData.precondition">
            <template slot="label">
              <div>
                Vorerkrankung vorhanden
              </div>
            </template>
          </v-checkbox>
          <div v-if="medicalData.precondition">
            <v-textarea
              outlined
              style="max-width: 600px"
              v-model="medicalData.preconditionDescription"
              label="Beschreibung der Vorerkrankung"
              prepend-icon="local_hospital"
              :rules="[
                v =>
                  (!!v && medicalData.precondition) ||
                  'Bitte Vorerkrankung beschreiben'
              ]"
            />
          </div>
          <v-checkbox v-model="medicalData.medication">
            <template slot="label">
              <div>
                Medikation
              </div>
            </template>
          </v-checkbox>
          <div v-if="medicalData.medication">
            <v-textarea
              outlined
              style="max-width: 600px"
              v-model="medicalData.medicationDescription"
              label="Medikamente"
              prepend-icon="local_pharmacy"
              :rules="[
                v =>
                  (!!v && medicalData.medication) ||
                  'Bitte benötigte Medikamente angeben'
              ]"
            />
            <v-checkbox v-model="medicalData.noSelfMedication">
              <template slot="label">
                <div>
                  Medikation durch das Leiterteam
                </div>
              </template>
            </v-checkbox>
            <div v-if="medicalData.noSelfMedication">
              <v-textarea
                outlined
                style="max-width: 600px"
                v-model="medicalData.medicationPlan"
                label="Medikationsplan"
                prepend-icon="schedule"
                :rules="[
                  v =>
                    (!!v && medicalData.noSelfMedication) ||
                    'Bitte Medikationsplan angeben'
                ]"
              />
            </div>
          </div>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn text class="ml-2" @click="back()">Zurück</v-btn>
        <v-btn color="primary" @click="next()">
          Weiter
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-stepper-content>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "MedicalData",
  props: {
    goToStep: Function,
    step: Number,
    eventId: Number
  },
  data() {
    return {
      medicalData: {
        bepanthen: false,
        fenistil: false,
        octenisept: false,
        tick: true,
        precondition: false,
        preconditionDescription: "",
        medication: false,
        medicationDescription: "",
        noSelfMedication: false,
        medicationPlan: ""
      }
    };
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    registrationProgress() {
      let index = this.$store.state.events.registrationsInProgress
        .map(reg => reg.eventId)
        .indexOf(this.eventId);
      return this.$store.state.events.registrationsInProgress[index];
    }
  },
  methods: {
    ...mapActions("events", [
      "setStepDone",
      "setStepError",
      "saveRegistrationState"
    ]),
    next() {
      if (this.$refs.medicalDataForm.validate()) {
        this.saveState();
        this.setStepError({ eventId: this.eventId, value: false });
        this.setStepDone({ eventId: this.eventId, step: this.step });
        this.goToStep(this.step + 1);
      } else {
        this.setStepError({ eventId: this.eventId, value: true });
      }
    },
    back() {
      this.saveState();
      this.setStepError({ eventId: this.eventId, value: false });
      this.setStepDone({ eventId: this.eventId, step: this.step - 2 });
      this.goToStep(this.step - 1);
    },
    saveState() {
      let payload = {
        eventId: this.eventId,
        field: "medicalData",
        data: this.medicalData
      };
      this.saveRegistrationState(payload);
    },
    loadState() {
      this.medicalData = this.registrationProgress.medicalData;
    }
  },
  mounted() {
    this.loadState();
    window.addEventListener("beforeunload", this.saveState);
  },
  beforeDestroy() {
    window.removeEventListener("beforeunload", this.saveState);
  }
};
</script>

<style scoped>
.radioLabel {
  width: 500px;
}
</style>
