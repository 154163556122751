<template>
  <v-stepper-content :step="step">
    <v-card>
      <v-card-title>
        Stammdaten
      </v-card-title>
      <v-card-subtitle>
        Bitte überprüfe, ob alle deine Stammdaten noch korrekt sind.
      </v-card-subtitle>
      <v-card-text class="">
        <v-list dense max-width="600">
          <v-list-item class="pr-0">
            <v-list-item-icon>
              <v-icon>mail</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <div class="font-weight-light">Account-Email:</div>
              {{ userInfo.accountEmail }}
            </v-list-item-content>
            <v-card-actions>
              <v-btn icon @click="changeEmailDialog = true">
                <v-icon>
                  edit
                </v-icon>
              </v-btn>
            </v-card-actions>
          </v-list-item>
          <v-divider class="my-4" />
          <v-list-item>
            <v-list-item-icon>
              <v-icon>person</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <div class="font-weight-light">Name:</div>
              {{ userInfo.forename + " " + userInfo.surname }}
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-icon>
              <v-icon>cake</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <div class="font-weight-light">Geburtsdatum:</div>
              {{ getFormattedBirthday() }}
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-icon>
              <v-icon>place</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <div class="font-weight-light">Anschrift:</div>
              {{
                userInfo.street +
                  " " +
                  userInfo.postalCode +
                  " " +
                  userInfo.city
              }}
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-icon>
              <v-icon>local_phone</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <div class="font-weight-light">Telefon:</div>
              {{ getFormattedPhoneNumbers() }}
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-alert text type="info" border="left" max-width="600">
          <div>Sind manche Informationen über dich nicht korrekt?</div>
          <div>
            Schreibe eine Mail an
            <a
              href="mailto:scouttools@dpsg-ketsch.de?subject=Fehlerhafte Personendaten"
              >scouttools@dpsg-ketsch.de</a
            >.
          </div>
          <div>
            Unabhängig davon kannst du die Anmeldung fortsetzen.
          </div>
        </v-alert>
      </v-card-text>
      <v-card-actions>
        <v-btn text class="ml-2" @click="cancelRegistrationDialog = true"
          >Anmeldung Abbrechen</v-btn
        >
        <v-btn color="primary" @click="next()">
          Weiter
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-dialog v-model="changeEmailDialog" width="600">
      <change-email
        :cancel="
          () => {
            changeEmailDialog = false;
          }
        "
      />
    </v-dialog>
    <v-dialog v-model="cancelRegistrationDialog" width="400">
      <v-card>
        <v-card-title>
          Registrierung Abbrechen?
        </v-card-title>
        <v-card-text>
          Die Registrierung wird abgebrochen und alle bereits eingegebenen Daten
          werden gelöscht.
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn text @click="cancelRegistrationDialog = false">
            Zurück
          </v-btn>
          <v-btn color="error" @click="cancelRegistration()">
            Registrierung Abbrechen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-stepper-content>
</template>

<script>
import Parser from "../../utils/Parser";
import ChangeEmail from "../user/ChangeEmail";
import { mapActions } from "vuex";

export default {
  name: "BasicData",
  props: {
    goToStep: Function,
    step: Number,
    eventId: Number
  },
  components: {
    "change-email": ChangeEmail
  },
  data() {
    return {
      changeEmailDialog: false,
      cancelRegistrationDialog: false
    };
  },
  computed: {
    userInfo() {
      return this.$store.state.user.userInfo;
    }
  },
  methods: {
    ...mapActions("events", ["setStepDone", "cancelRegistrationInProgress"]),
    getFormattedBirthday() {
      return Parser.parseDate(new Date(this.userInfo.birthday));
    },
    getFormattedPhoneNumbers() {
      return Parser.parseTelefonNumber(
        this.userInfo.phone1,
        this.userInfo.phone2,
        this.userInfo.phone3
      );
    },
    next() {
      this.setStepDone({ eventId: this.eventId, step: this.step });
      this.goToStep(this.step + 1);
    },
    cancelRegistration() {
      this.$router.go(-1);
      this.cancelRegistrationInProgress();
    }
  }
};
</script>

<style scoped></style>
