<template>
  <v-stepper-content :step="step">
    <v-card>
      <div v-if="dietModuleActive">
        <v-card-title>
          Ernährung
        </v-card-title>
        <v-card-subtitle>
          Bitte mache Angaben zu deiner Ernährung
        </v-card-subtitle>
        <v-card-text>
          <v-form ref="dietForm">
            <v-autocomplete
              style="max-width: 600px"
              v-model="dietData.diet"
              :items="diets"
              prepend-icon="restaurant"
              :rules="[v => !!v || 'Ernährungsangabe benötigt']"
            >
              <template v-slot:label>
                <div>
                  Ernährung:
                </div>
              </template>
            </v-autocomplete>
            <v-checkbox v-model="dietData.intolerance">
              <template slot="label">
                <div>
                  Lebensmittel Unverträglichkeiten/Allergien
                </div>
              </template>
            </v-checkbox>
            <div v-if="dietData.intolerance">
              <v-textarea
                outlined
                v-model="dietData.intoleranceDescription"
                label="Unverträglichkeiten/Allergien"
                style="max-width: 600px"
                :rules="[
                  v =>
                    (!!v && dietData.intolerance) ||
                    'Angabe zu Unverträglichkeiten/Allergien benötigt'
                ]"
              />
            </div>
          </v-form>
        </v-card-text>
      </div>
      <div v-if="swimmingModuleActive">
        <v-card-title>
          Schwimmen
        </v-card-title>
        <v-card-subtitle>
          Bitte mache Angaben zu den Schwimmfähigkeiten
        </v-card-subtitle>
        <v-card-text>
          <v-radio-group v-model="swimmingData.swimmer" :row="!isMobile">
            <template v-slot:label>
              <div :class="{ radioLabel: !isMobile }">
                Schwimmen
              </div>
            </template>
            <v-radio label="Schwimmer" :value="true"></v-radio>
            <v-radio
              label="Nicht-Schwimmer"
              :value="false"
            ></v-radio> </v-radio-group
          ><v-radio-group
            v-model="swimmingData.onlySupervisedSwimming"
            :row="!isMobile"
          >
            <template v-slot:label>
              <div :class="{ radioLabel: !isMobile }">
                Schwimmen - Aufsicht
              </div>
            </template>
            <v-radio label="nein (unbeaufsichtigt)" :value="false"></v-radio>
            <v-radio label="ja (beaufsichtigt)" :value="true"></v-radio>
          </v-radio-group>
        </v-card-text>
      </div>
      <div v-if="pocketMoneyModuleActive">
        <v-card-title>
          Taschengeld
        </v-card-title>
        <v-card-subtitle>
          Es wird die Möglichkeit geben Taschengeld auszugeben
        </v-card-subtitle>
        <v-card-text>
          <v-checkbox v-model="pocketMoneyData.managedPocketMoney">
            <template slot="label">
              <div>
                Taschengeld soll von den Leitern verwaltet werden
              </div>
            </template>
          </v-checkbox>
        </v-card-text>
      </div>
      <v-card-actions>
        <v-btn text class="ml-2" @click="back()">Zurück</v-btn>
        <v-btn color="primary" @click="next()">
          Weiter
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-stepper-content>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "GeneralData",
  props: {
    goToStep: Function,
    step: Number,
    eventId: Number
  },
  data() {
    return {
      dietData: {
        diet: "",
        intolerance: false,
        intoleranceDescription: ""
      },
      diets: ["Fleisch", "Vegetarisch", "Vegan"],
      swimmingData: {
        swimmer: false,
        onlySupervisedSwimming: true
      },
      pocketMoneyData: {
        managedPocketMoney: false
      }
    };
  },
  computed: {
    event() {
      let index = this.$store.state.events.events
        .map(event => event.id)
        .indexOf(this.eventId);
      return this.$store.state.events.events[index];
    },
    registrationProgress() {
      let index = this.$store.state.events.registrationsInProgress
        .map(reg => reg.eventId)
        .indexOf(this.event.id);
      return this.$store.state.events.registrationsInProgress[index];
    },
    dietModuleActive() {
      return this.event.registrationInformation.registrationModuleDiet;
    },
    swimmingModuleActive() {
      return this.event.registrationInformation.registrationModuleSwimming;
    },
    pocketMoneyModuleActive() {
      return this.event.registrationInformation.registrationModulePocketMoney;
    },
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    }
  },
  methods: {
    ...mapActions("events", [
      "setStepDone",
      "setStepError",
      "saveRegistrationState"
    ]),
    next() {
      if (this.$refs.dietForm.validate() && this.dietModuleActive) {
        this.saveState();
        this.setStepError({ eventId: this.eventId, value: false });
        this.setStepDone({ eventId: this.eventId, step: this.step });
        this.goToStep(this.step + 1);
      } else {
        this.setStepError({ eventId: this.eventId, value: true });
      }
    },
    back() {
      this.saveState();
      this.setStepError({ eventId: this.eventId, value: false });
      this.setStepDone({ eventId: this.eventId, step: this.step - 2 });
      this.goToStep(this.step - 1);
    },
    saveState() {
      if (this.dietModuleActive) {
        let payload = {
          eventId: this.eventId,
          field: "dietData",
          data: this.dietData
        };
        this.saveRegistrationState(payload);
      }
      if (this.swimmingModuleActive) {
        let payload = {
          eventId: this.eventId,
          field: "swimmingData",
          data: this.swimmingData
        };
        this.saveRegistrationState(payload);
      }
      if (this.pocketMoneyData) {
        let payload = {
          eventId: this.eventId,
          field: "pocketMoneyData",
          data: this.pocketMoneyData
        };
        this.saveRegistrationState(payload);
      }
    },
    loadState() {
      if (this.dietModuleActive) {
        this.dietData = this.registrationProgress.dietData;
      }
      if (this.swimmingModuleActive) {
        this.swimmingData = this.registrationProgress.swimmingData;
      }
      if (this.pocketMoneyData) {
        this.pocketMoneyData = this.registrationProgress.pocketMoneyData;
      }
    }
  },
  mounted() {
    this.loadState();
    window.addEventListener("beforeunload", this.saveState);
  },
  beforeDestroy() {
    window.removeEventListener("beforeunload", this.saveState);
  }
};
</script>

<style scoped>
.radioLabel {
  width: 250px;
}
.v-radio {
  min-width: 200px;
}
</style>
