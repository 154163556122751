import { render, staticRenderFns } from "./RegistrationEditor.vue?vue&type=template&id=4871fe2e&scoped=true&"
import script from "./RegistrationEditor.vue?vue&type=script&lang=js&"
export * from "./RegistrationEditor.vue?vue&type=script&lang=js&"
import style0 from "./RegistrationEditor.vue?vue&type=style&index=0&id=4871fe2e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4871fe2e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VAutocomplete,VCardSubtitle,VCardText,VCardTitle,VCheckbox,VCol,VForm,VIcon,VRadio,VRadioGroup,VTextarea})
