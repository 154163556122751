<template>
  <v-stepper-content :step="step">
    <v-card>
      <v-card-text v-if="!guestAccountAlreadyExists">
        <div>
          Zunächst müssen wir erstmal wissen, wer du bist.
        </div>
        <div>
          Bitte gebe deine persönlichen Informationen an.
        </div>
        <v-form ref="guestUserForm" class="mt-3" style="max-width: 650px;">
          <v-text-field
            v-model="guestUserForm.forename"
            label="Vorname"
            prepend-icon="person"
            :rules="forenameRules"
          />
          <v-text-field
            v-model="guestUserForm.surname"
            label="Nachname"
            class="ml-8"
            :rules="surnameRules"
          />
          <v-menu
            v-model="birthdayPickerMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="guestUserForm.birthday"
                label="Geburtsdatum"
                prepend-icon="cake"
                readonly
                v-bind="attrs"
                v-on="on"
                :rules="birthdayRules"
              />
            </template>
            <v-date-picker
              v-model="guestUserForm.birthday"
              @input="birthdayPickerMenu = false"
              :max="new Date().toISOString().substr(0, 10)"
              min="1950-01-01"
            ></v-date-picker>
          </v-menu>
        </v-form>
      </v-card-text>
      <v-card-text v-else>
        <reduced-user-detail-list :user="user" />
      </v-card-text>
      <v-card-actions>
        <v-btn text class="ml-2" @click="cancelRegistrationDialog = true"
          >Anmeldung Abbrechen</v-btn
        >
        <v-btn color="primary" @click="next()">
          Weiter
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-dialog v-model="cancelRegistrationDialog" width="400">
      <v-card>
        <v-card-title>
          Registrierung Abbrechen?
        </v-card-title>
        <v-card-text>
          Die Registrierung wird abgebrochen und alle bereits eingegebenen Daten
          werden gelöscht.
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn text @click="cancelRegistrationDialog = false">
            Zurück
          </v-btn>
          <v-btn color="error" @click="cancelRegistration()">
            Registrierung Abbrechen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-stepper-content>
</template>

<script>
import { mapActions } from "vuex";
import UsersAPI from "@/api/UsersAPI";
import AccountAPI from "@/api/AccountAPI";
import ReducedUserDetailList from "@/components/user/ReducedUserDetailList";

export default {
  name: "GuestUserForm",
  components: { ReducedUserDetailList },
  props: {
    goToStep: Function,
    step: Number,
    eventId: Number
  },
  data() {
    return {
      guestAccountAlreadyExists: false,
      guestUserForm: {
        forename: "",
        surname: "",
        birthday: ""
      },
      forenameRules: [v => !!v || "Bitte gebe deinen Vornamen ein."],
      surnameRules: [v => !!v || "Bitte gebe deinen Nachnamen ein."],
      birthdayRules: [v => !!v || "Bitte gebe dein Geburtsdatum ein."],
      birthdayPickerMenu: false,
      cancelRegistrationDialog: false
    };
  },
  computed: {
    token() {
      return this.$route.params.token;
    },
    user() {
      return this.$store.state.user.userInfo;
    }
  },
  methods: {
    ...mapActions("events", [
      "setStepDone",
      "setStepError",
      "cancelRegistrationInProgress"
    ]),
    ...mapActions("user", ["insertUserInfo"]),
    next() {
      if (this.guestAccountAlreadyExists) {
        this.setStepError({ eventId: this.eventId, value: false });
        this.setStepDone({ eventId: this.eventId, step: this.step });
        this.goToStep(this.step + 1);
      } else {
        if (!this.$refs.guestUserForm.validate()) {
          this.setStepError({ eventId: this.eventId, value: true });
          return;
        }
        UsersAPI.createGuestUser({
          ...this.guestUserForm,
          birthday: new Date(this.guestUserForm.birthday + " 00:00:00"),
          token: this.token
        })
          .then(user => {
            this.guestAccountAlreadyExists = true;
            this.insertUserInfo(user);
            this.setStepError({ eventId: this.eventId, value: false });
            this.setStepDone({ eventId: this.eventId, step: this.step });
            this.goToStep(this.step + 1);
          })
          .catch(error => {
            console.log(error);
            this.setStepError({ eventId: this.eventId, value: true });
          });
      }
    },
    cancelRegistration() {
      this.cancelRegistrationInProgress();
    },
    getUserInfoByRegistrationToken() {
      AccountAPI.getUserInfoByRegistrationToken(this.token).then(user => {
        this.insertUserInfo(user);
        this.guestAccountAlreadyExists = true;
      });
    }
  },
  mounted() {
    this.getUserInfoByRegistrationToken();
  }
};
</script>

<style scoped></style>
