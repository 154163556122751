<template>
  <v-stepper-content :step="step">
    <v-card>
      <v-card-title>
        Notfallkontakt Daten
      </v-card-title>
      <v-card-subtitle>
        Bitte überprüfe deine angegebenen Notfallkontakte.
      </v-card-subtitle>
      <v-card-text>
        <v-btn
          class="mt-4"
          @click="openEmergencyContactForm(true, {})"
          :color="
            errorState && emergencyContacts.length === 0 ? 'error' : 'primary'
          "
        >
          <v-icon class="mr-2">
            person_add
          </v-icon>
          Notfallkontakt hinzufügen
        </v-btn>
        <v-row>
          <emergency-contact
            class="ma-3"
            v-for="contact in emergencyContacts"
            :contact="contact"
            :open-emergency-edit-dialog="openEmergencyContactForm"
            :key="contact.id"
          />
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn text class="ml-2" @click="back()">Zurück</v-btn>
        <v-btn color="primary" @click="next()">
          Weiter
        </v-btn>
      </v-card-actions>
    </v-card>
    <emergency-contact-form ref="emergencyContactForm" />
  </v-stepper-content>
</template>

<script>
import EmergencyContactForm from "../user/EmergencyContactForm";
import EmergencyContact from "../user/EmergencyContact";
import { mapActions } from "vuex";

export default {
  name: "EmergencycontactsData",
  props: {
    goToStep: Function,
    step: Number,
    eventId: Number
  },
  data() {
    return {
      errorState: false
    };
  },
  components: {
    "emergency-contact-form": EmergencyContactForm,
    "emergency-contact": EmergencyContact
  },
  computed: {
    emergencyContacts() {
      return this.$store.state.user.userInfo.emergencyContacts;
    }
  },
  methods: {
    ...mapActions("events", ["setStepDone", "setStepError"]),
    openEmergencyContactForm(newContact, contact) {
      this.$refs.emergencyContactForm.open(newContact, contact);
    },
    next() {
      if (this.emergencyContacts.length > 0) {
        this.setStepError({ eventId: this.eventId, value: false });
        this.setStepDone({ eventId: this.eventId, step: this.step });
        this.goToStep(this.step + 1);
      } else {
        this.errorState = true;
        this.setStepError({ eventId: this.eventId, value: true });
      }
    },
    back() {
      this.setStepError({ eventId: this.eventId, value: false });
      this.setStepDone({ eventId: this.eventId, step: this.step - 2 });
      this.goToStep(this.step - 1);
    }
  }
};
</script>

<style scoped></style>
